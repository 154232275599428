import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {DateTime, Duration} from 'luxon';

import events from '../state/events';
import users from '../state/users';
import worlds from '../state/worlds';
import packages from '../state/packages';

import DateTimePicker from './DateTimePicker.vue';
import * as human from '@/functions/human';
import rules from '@/functions/rules';
import WorldPicker from '@/components/WorldPicker.vue';

interface Unit {
  singular?: string,
  plural?: string,
  text: string,
  value: string,
  cost?: number,
  duration?: Duration;
}
interface PricingStrategy {
  unit: Unit;
  cost: number;
  qty: number;
}

@Component({
  components: {
    DateTimePicker,
    WorldPicker,
  },
})
export default class EventsCreate extends Vue {
  title="";
  buildId = '';
  defaultBuildId = this.$spacewalk.reactive.buildId;
  buildIdRules = [
    rules.notEmpty,
  ];
  cloudHosted = events.cloudHostingDisabledReason === undefined;
  cloudHostedHint = false;
  publicEvent = events.canHostPublic;
  publicEventHint = false;
  defaultHostname = '127.0.0.1';
  hostname = '';
  port = '';
  defaultPort = '7777';
  worldId = this.$route.params.world || 'Lobby';
  serverImage = '';
  get serverImagePlaceholder(){
    return `viewalk-game-server:${this.defaultBuildId}`
  }
  get placeholder(){
    if (!users.current){
      return '';
    }
    let string = `${users.current.displayName}’`;
    if (users.current.displayName.slice(-1) !== 's'){
      string += 's';
    }
    string += ' event';
    return string;
  }
  valid = false;
  now = true;
  duration = 1;
  adjustDuration(amount: number){
    this.duration = Math.max(1, this.duration+amount);
  }
  unit = 'hours';
  currency = '$';
  visibility = 'none';
  perpetual = false;
  // get perpetual(){
  //   return this.unit === 'perpetual';
  // }
  startsAt = DateTime.now().toISO();
  endsAt = '';
  beforeMount(){
    this.onDurationChange();
    this.calculateEndsAt();
    if (this.$spacewalk.ue){
      console.log('Getting default hostname and port ...');
      this.$spacewalk.ue.GetIP().then(ip => {
        console.log('ip', ip);
        this.defaultHostname = ip;
      });
      this.$spacewalk.ue.GetPort().then(port => {
        console.log('port', port);
        this.defaultPort = port.toString();
      });
      this.$spacewalk.ue.GetCurrentMapId().then(mapId => {
        const world = worlds.getByPakId(mapId);
        if (world){
          this.worldId = world._id;
        }
      })
    }
  }
  units: Unit[] = [
    {
      text: '',
      duration: Duration.fromObject({hours: 1}),
      singular: 'Hour',
      plural: 'Hours',
      value: 'hours',
      cost: 9,
    },
    {
      text: '',
      duration: Duration.fromObject({days: 1}),
      singular: 'Day',
      plural: 'Days',
      value: 'days',
      cost: 29,
    },
    {
      text: '',
      duration: Duration.fromObject({months: 1}),
      singular: 'Month',
      plural: 'Months',
      value: 'months',
      cost: 200,
    },
    {
      text: 'Perpetual',
      value: 'perpetual',
    },
  ];
  @Watch('duration')
  onDurationChange(){
    for (const unit of this.units){
      if (this.duration === 1 && unit.singular){
        unit.text = unit.singular;
      }
      else if (unit.plural){
        unit.text = unit.plural;
      }
    }
  }
  calculateEndsAt(){
    if (this.perpetual){
      console.log('Perpetual');
      return;
    }
    const unit = this.units.find(u => u.value === this.unit);
    if (!unit || !unit.duration){
      console.log('Missing unit or duration', unit);
      return;
    }
    const duration = unit.duration.mapUnits(n => n*this.duration);
    this.endsAt = DateTime.fromISO(this.startsAt).plus(duration).toISO();
  }
  calculateCost(){
    const duration = DateTime.fromISO(this.endsAt).diff(DateTime.fromISO(this.startsAt));
    const strategies: PricingStrategy[] = [];
    for (const unit of this.units){
      if (!unit.duration || !unit.cost){
        continue;
      }
      const qty = Math.max(1, duration.toMillis() / unit.duration.toMillis());
      strategies.push({
        cost: qty * unit.cost,
        qty,
        unit,
      })
    }
  }
  get timeDescription(){
    let description = 'Starts ';
    const humanDateStart = human.date(this.startsAt);
    const humanTimeStart = human.time(this.startsAt);
    if (this.now){
      description += 'immediately'
    } else {
      description += `${humanDateStart} at ${humanTimeStart}`
    }
    description += ' and runs untill ';
    if (this.perpetual){
      description += 'stopped manually';
    } else {
      const humanDateEnd = human.date(this.endsAt);
      const humanTimeEnd = human.time(this.endsAt);
      if (humanDateEnd !== humanDateStart){
        description += `${humanDateEnd} at `;
      }
      description += humanTimeEnd;
    }
    description += '.';
    return description;
  }
  get price(){
    const unit = this.units.find(u => u.value === this.unit);
    if (!unit || !unit.cost){
      return `${this.currency}9 per hour. Capped at ${this.currency}29 per day and ${this.currency}200 per month.`;
    }
    return `Total: ${this.currency}${unit.cost * this.duration}`;
  }
  submitting = false;
  submit(){
    if (!this.valid){
      return;
    }
    this.submitting = true;
    const pakId = worlds.get(this.worldId).pakId;
    events.create({
      buildId: Number(this.buildId || this.defaultBuildId),
      title: this.title || this.placeholder,
      locallyHosted: !this.cloudHosted,
      address: !this.cloudHosted && (this.hostname || this.defaultHostname) || undefined,
      port: Number(!this.cloudHosted && (this.port || this.defaultPort)) || undefined,
      mapId: this.worldId,
      public: this.publicEvent,
      serverImage: this.serverImage,
    }).then(async event=>{
      if (this.$spacewalk.ue && event.locallyHosted){
        await this.$spacewalk.ue.Host(event._id, pakId || 'Lobby');
        events.current = event._id;
        this.$router.replace({
          name: 'Playing',
        });
      } else {
        this.$router.replace({
          name: 'Event',
          params: {
            id: event._id,
          },
        });
      }
    });
  }
  get downloadProgress(){
    if (!this.worldId){
      return 0;
    }
    const world = worlds.get(this.worldId);
    if (!world.pakId){
      return 0;
    }
    return packages.get(world.pakId).downloadProgress;
  }
  get cloudHostingDisabledReason(){
    return events.cloudHostingDisabledReason;
  }
  get publicSwitchDisabled(){
    return !events.canHostPublic;
  }
}