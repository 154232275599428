import Vue from 'vue';
import EventTarget from '@ungap/event-target'

// import experiences, { Experience } from '@/state/experiences';
import * as Spacewalk from 'viewalk-api-client/src';
import type { Auth, Realtime, Achievements, Settings } from 'viewalk-types';

import events from '@/state/events';
import worlds from '@/state/worlds';
import users from '@/state/users';
import packages from '@/state/packages';
import router from '@/router'

import type Bridge from './Bridge';
import bridgeProxy from './BridgeProxy';

type ErrorCallback = (error: string)=>void;
type CompletionCallback = (success: boolean)=>void;

declare global {
  interface Window {
    ue: {
      bridge: Bridge,
    },
    webview: WebView;
  }
}
function getBlankUser(): Required<Auth.UserForClient>{
  return {
    _id: '',
    emailVerified: false,
    email: '',
    phone: '',
    phoneVerified: false,
    claims: {
      viewalk: '',
    },
    token: '',
  };
}
class WebView extends EventTarget {
  // experienceLoading(_id: string, progress: number){
  //   experiences.get(_id).downloadProgress = progress;
  // }
  requestHide(){
    if (client.reactive.hideDelayers.length){
      client.reactive.hideRequested = true;
      return;
    }
    window.ue.bridge.Hide();
  }
  setRefreshToken(token: string){
    if (!token){
      client.native.auth.setUser(getBlankUser());
      return;
    }
    client.native.auth.setRefreshToken(token);
    client.native.auth.refreshUserToken().then(()=>{
      // client.reactive.auth
      users.get(client.native.auth.userId);
    });
  }
  setCurrentSession(_id: string){
    events.current = _id;
  }
  setCurrentMap(_id: string){
    packages.current = _id;
  }
  // pendingWorld = '';
  showWorld(_id: string){
    // this.pendingWorld = _id;
    router.push(`/worlds/${_id}`);
  }
  showEvent(_id: string){
    // this.pendingWorld = _id;
    router.push(`/events/${_id}`);
  }
  showUser(_id: string){
    // this.pendingWorld = _id;
    router.push(`/users/${_id}`);
  }
  setPakDownloadProgress(_id: string, progress: number){
    packages.setProgress(_id, progress);
  }
  setPakDownloadComplete(_id: string, success: boolean){
    packages.setComplete(_id, success);
  }
  onNetworkFailure(failureType: number, errorString: string){
    this.dispatchEvent(new CustomEvent('NetworkFailure', {detail: {
      failureType,
      errorString,
    }}));
    console.error('NetworkFailure', failureType, errorString);
  }
  onTravelFailure(failureType: number, errorString: string){
    this.dispatchEvent(new CustomEvent('TravelFailure', {detail: {
      failureType,
      errorString,
    }}));
    console.error('TravelFailure', failureType, errorString);
  }
  receiveRealtimeMessage(json: string){
    const data = JSON.parse(json) as Realtime.WebSocketMessageFromServer;
    client.native.realtime.receive(data);
  }
  onAchievement(_id: string){
    client.native.achievements.onAchievement({
      _id,
      time: new Date(),
    });
  }
  onAccumulate(key: string, value: number){
    if (key === 'movetime'){
      client.reactive.bufferedMoveTime = value;
    }
  }
}
window.webview = new WebView();

interface State {
  auth: Required<Auth.User>;
  buildId: number;
  version: string;
  latestBuildId: number;
  latestIosVersion: string;
  isHosting: boolean;
  developerMode: boolean;
  settings: Settings.Settings;
  supportedFeatures: string[];
  hideDelayers: any[];
  hideRequested: boolean;
  bufferedMoveTime: number;
}

class SpacewalkClient extends Vue {
  native: Spacewalk.Client;
  webview = window.webview;
  addHideDelayer(delayer: any){
    if (this.reactive.hideDelayers.includes(delayer)){
      return;
    }
    this.reactive.hideDelayers.push(delayer);
  }
  removeHideDelayer(delayer: any){
    const index = this.reactive.hideDelayers.indexOf(delayer);
    if (index < 0){
      return;
    }
    this.reactive.hideDelayers.splice(index, 1);
  }
  signOut(){
    if (this.ue){
      console.log('signOut via UE');
      return this.ue.SignOut();
    } else {    
      console.log('signOut via native');
      this.native.auth.signOut();
    }
  }
  refreshCurrentSessionId(){
    if (!this.ue){
      events.current = '';
    } else {
      this.ue.GetCurrentSessionId().then(sessionId => {
        events.current = sessionId;
      })
    }
  }
  reactive = new Vue<State>({
    data: {
      auth: getBlankUser(),
      buildId: 0,
      version: '0.0.0',
      latestBuildId: 0,
      latestIosVersion: '0.0.0',
      isHosting: false,
      developerMode: false,
      settings: {
        onboardingMap: '',
        cloudHostingDisabledReason: '',
      },
      supportedFeatures: [],
      hideDelayers: [],
      hideRequested: false,
      bufferedMoveTime: 0,
    },
    watch: {
      // whenever question changes, this function will run
      hideDelayers(newVal: any[], oldVal: any[]) {
        if (!newVal.length && this.hideRequested){
          this.hideRequested = false;
          window.ue.bridge.Hide();
        }
      }
    },
  });
  get ue(){
    return window.ue && bridgeProxy;
  };
  constructor(){
    super();
    // let refreshToken: string | undefined = undefined;
    let endpoint: string | undefined = undefined;
    const dev = window.location.port;
    if (dev){
      endpoint = window.location.protocol+'//'+window.location.hostname+':3000';
    }
    const realtimeRelay: Realtime.Relay | undefined = dev ? undefined : { send: ()=>{} };
    this.native = new Spacewalk.Client('WebView', endpoint, undefined, realtimeRelay);
    // if (process.env.NODE_ENV !== 'development'){
      this.native.auth.externalRefreshToken = true;

    // }
    setTimeout(()=>{
      if (!this.ue){
        console.log("No UE binding detected. externalRefreshToken = false");
        this.native.auth.externalRefreshToken = false;
        this.native.auth.autoLogin();
      }
    }, 2*1000);
    document.addEventListener('visibilitychange', ()=>{
      if (!document.hidden){

      }
    }, false);
    // this.native.assets.refreshMaps();
  }
}
declare module 'vue/types/vue' {
  interface Vue {
    $spacewalk: SpacewalkClient;
  }
}
export const client = new SpacewalkClient();
const nativeSetKeyboxId = client.native.auth.setKeyboxId.bind(client.native.auth);
client.native.auth.setKeyboxId = keyboxId => {
  if (client.ue){
    client.ue.SetKeyboxId(keyboxId || '');
  } else {
    nativeSetKeyboxId(keyboxId);
  }
}
// client.native.auth.on('refreshTokenUpdated', token =>{
//   client.native.auth.refreshUserToken();
// });
client.native.auth.on('authChange', (user: Auth.User) =>{
  const completeUser = Object.assign(getBlankUser(), user);
  for (const [key, value] of Object.entries(completeUser)){
    (client.reactive.auth as any)[key] = value;
  }
  client.native.settings.read().then(hello => {
    client.reactive.settings = Object.assign(client.reactive.settings, hello.settings);
    client.native.users.read(user._id).then(profile => {
      if (!profile.achievements?.viewalking101){
        client.webview.requestHide();
      }
    })
  // alert('settings read');
  //   client.ue.GetCurrentMapId().then(mapId => {
  // alert('mapId: '+mapId+' onboardingMap: '+client.reactive.settings.onboardingMap);
  //   const onboardingWorld = worlds.get(hello.settings.onboardingMap);
  //     if (mapId === client.reactive.settings.onboardingMap){
  //       client.webview.requestHide();
  //     }
  //   })
    // if (!hello.settings.onboardingMap){
    //   return;
    // }
    // const onboardingWorld = worlds.get(hello.settings.onboardingMap);
    // if (!users.get(user._id).achievements.viewalking101){
    //   console.log('User has not achieved viewalking101. Launching onboarding map.')
    //   if (!client.ue){
    //     return;
    //   }
    //   client.ue.PlaySolo(onboardingWorld._id, onboardingWorld.pakId, onboardingWorld.title);
    // }
  });
  events.refresh();
  worlds.refresh();
  client.native.settings.read().then(hello => {
    client.reactive.latestBuildId = hello.settings.latestVersion || 0;
    client.reactive.latestIosVersion = hello.settings.latestIosVersion || '0.0.0';
  });
});
export default function install () {
  Vue.prototype.$spacewalk = client;
  // router.beforeEach((to, from) => {
  //   if (client.webview.pendingWorld){
  //     router.beforeEach
  //   }
  // });
  const timer = setInterval(async ()=>{
    if (!client.ue){
      return;
    }
    client.ue.SetWebViewReady(true);
    await client.ue.GetRefreshToken().then(client.webview.setRefreshToken);
    client.ue.GetBuildId().then(buildId => {
      client.reactive.buildId = buildId;
    });
    client.ue.GetVersion().then(version => {
      client.reactive.version = version;
    });
    client.ue.GetSupportedFeatures().then(features => {
      client.reactive.supportedFeatures = features;
    });
    clearInterval(timer);
  }, 1000);
  // client.native.auth.autoLogin();
}

Vue.use(install);
